

.register{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: linear-gradient(60deg, #00315b 55%,#f9f9f9 45%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-reg{
    position: relative;
    width: 60%;
    height: 80vh;
  background-color: #efefef;
  box-shadow: 1px 1px 2px gray;
  border: 0.5px solid gray;
  border-radius: 5px;

  }.form-reg h2{
    text-align: center;
    font-size: x-large;
    color: #00315b;
    padding: 30px;
  }.r-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }.name{
    display: flex;
    width: 100%;
  }.name  label{
   width: 35%;
   margin: 1%;
  }.name label input{
    width: 100%;
  }
 .r-form input:focus{
    outline: none;
  }.contact {
    display: flex;
    width: 100%;
  }.contact label{
    width: 35%;
    margin: 1%;
  }.contact label input{
    width: 100%;
  }.location{
    display: flex;
    width: 100%;
  }.location label{
    width: 25%;
    margin: 1%;
  }.location label input{
    width: 100%;
  }
  .r-form .sign{
    width: 100%;
    display: flex;


  }.sign .gender{
    display: flex;
    margin:0 20%;
  }.sign .gen{
    display: flex;
    flex-direction: column;
    margin-right: 20%;
  }
  .r-form  button{
 
    background-color: #00315b;
    border: none;
    color: #f9f9f9;
  }.r-form .sign h4{
    font-weight: 300;
  }.r-form .sign span{
    color: #1764ff;
    text-decoration: none;
  }.sign .gender h4{
  text-decoration: underline;
  }
 
  .to-sign{
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

  }.to-sign button{
 
 border-radius: 10px;
  }

  /* mobile inerface */
  @media (max-width:768px){
    .register{
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      background: linear-gradient(60deg, #00315b 55%,#f9f9f9 45%);
      height: 100vh;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
    }.form-reg{
      position: relative;
        width: 80%;
        height: 100%;
        overflow-y: auto;
        scrollbar-width: none;
      background-color: #efefef;
      box-shadow: 1px 1px 2px gray;
      border: 0.5px solid gray;
      border-radius: 5px;
       
    }.name{
     
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }.name label{
      width: 80%;
    }.name label input{
      width: 100%;
    }
    .contact{

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }.contact label{
      width: 80%;
    }.contact label input{
      width: 100%;
    }
    .location{
     
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }.location label{
      width: 80%;
    }.location label input{
      width: 100%;
    }.sign .gender{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      margin:0 20%;
    }.sign .gender label{
      width: 100%;
    }
    .sign .gen{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
     
    }
      .r-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }.form-reg h2{
        text-align: center;
        font-size: large;
        color: #00315b;
        padding: 5px;
      }.sign{
        display: flex;
      
      }
      .sign .to-sign{
        padding: 15px 50px;
        margin-left: 10px;
      }.to-sign button{
       
       }
  }