.App{
  padding: 0;
 margin: 0;
 position: sticky;

  width: 100%;
  

  box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    
}.dark-mode .App{
    background-color:#011528;
     text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.9); 
}