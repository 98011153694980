


/* MusicPlayer.css */

.music-player {
    display: flex;
justify-content: center;
    align-items: center;
    margin-top: 60px;
    background-color: #f9f9f9;
    width: 80%;
    height: 300px;
    box-shadow: 1px 2px 1px ccc;
    border-radius: 5px;
  }.music-section-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }.music-section-2{
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dark-mode .music-player{
    background-color: #00315B;
  }
  .Music-image{
    width: 250px;
    height: 250px;
    overflow: hidden;
    
}.Music-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.progress-bar{
width: 200px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

}.progress-bar input{
width: 250px;

}.download{
padding: 10px 5px;
background-color: #00315B;
color: #f9f9f9;
cursor: pointer; 
}
  
  .controls {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-bottom: 10px;
  }
  
  .progress-bar {
    width: 100%;
  }
  .download span{
    padding: 10px;
  }

    
  .loop-icon {
    color: #555;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .loop-icon.active {
    color: #2ecc71;
  }
  
  .progress-bar {
    width: 80%;
  }
  .track-info {
    margin-top: 10px;
  }
  @media (max-width:768px) {
    .music-player{
        width: 90%;
        height: 75%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        background-color: #ffffff;   
    }
    .Music-image{
        width: 250px;
        height: 200px;
        overflow: hidden;
        
    }.Music-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }.progress-bar{
       width: 200px;
       display: flex;
       align-items: center;
       flex-direction: column;
       justify-content: center;
    
    }.progress-bar input{
      width: 250px;
    }.download{
      padding: 10px 5px;
      background-color: #00315B;
      color: #f9f9f9;
      cursor: pointer;

    }
    
  }