body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }
  .Navbar {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
   padding: 0;
  }
 
.navba {
    background-color: #f9f9f9;
    color: gray;
    position: sticky;
    width: 100%;
    top: 0;
    padding: 10px 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 2px gray;
  }

  .dark-mode .navbar{
    background-color:#011528;
   
    text-shadow: 1px 1px 2px rgba(255,255,255,0.9);
}
  .navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  
  .navbar-items {
  
    display: flex;
    align-items: center;
  
  }
  
  .navbar-items a {
    color: black;
    opacity: 0.7;
    text-decoration: none;
    margin: 0 15px;
  }
  .navbar-items a:hover {
    color: gray;
    transition: 0.5s ease-in-out;
    transform: scaleX(1.1);
  }

  .menu-button {
    display: none;
 
    flex-direction: column;
    cursor: pointer;
    font-size: 25px; 
  margin-right: -30px;
   
  }
  
  
  @media (max-width: 768px) {
    .navbar-items {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 48.5px;
      left: 0;
      width: 100%;
      color: white;
      background-color: #ccc;
      font-weight: bold;
      z-index: 999;
    
      text-align: center;
    }  .dark-mode .navbar-items{
      background-color:#011528;
      text-shadow: rgba(255,255,255,0.9);
    
  }
    .navbar-items 
    .navHome,
    .nav-about,
    .nav-news,
    .nav-sign{
   
    padding: 20px 0;
    width: 100%;
    
    }
    .navbar.open .navbar-items {
      display: flex;
      align-items: center;
     background-color: #f9f9f9;
     width: 100%;
     height: 100vh;
     z-index: 999;

    }
    .menu-button {
      display: flex;
    }
  }
  .overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 998;
}
.navbar .open ~ .overlay{
    display: block;
}
