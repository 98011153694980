.login{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: linear-gradient(-60deg, #00315b 55%,#f9f9f9 45%);
    height: 100vh;
    width: 100%;
  }
  .form-container{
    position:relative;
    transform: translate(-40%,30%);
    left: 50%;
    width: 60%;
    
    background-color: #efefef;
    box-shadow: 1px 1px 2px gray;
  }.form-container h2{
    text-align: center;
    padding: 30px 0 0;
    font-size: xx-large;
    color: #00315b;
  }.login-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }.login-form .email-label {
    width: 100%;
  
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }.email-label input{
    width: 40%;
  }
  .login-form .password-label {
    width: 100%;

    display: flex;
    justify-content: center;
  

  }.login-form .password-label input{
    width: 40%;
  }
  .login-form input{
    padding: 10px 0;
   
    background-color: #efefef;
    border:none;
  }.login-form input:focus{
   outline: none;
  }
 
.fp{

    color: #1764ff;
  }
  .login-form .loginButton{
    text-decoration: none;
    padding: 2% 10%;
    margin-top: 30px;
    background-color: #00315b;
    color: #f9f9f9;
    border: none;
    border-radius: 10px;
    
  }button Link{
    color: #f9f9f9;
  }
  
  .login-form .loginButton:hover{
    background-color: #063186;
    color: #f9f9f9;
    cursor: pointer;    
  }

  @media (max-width:768px) {
    .login{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background: linear-gradient(-60deg, #00315b 55%,#f9f9f9 45%);
        height: 100vh;
        width: 100%;
      } .form-container{
        position:absolute;
        transform: translate(-50%,20%);
        left: 50%;
        width: 80%;
        height: 60%;
        font-size: smaller;
        background-color: #efefef;
        box-shadow: 1px 1px 2px gray;
        background: rgba(255, 255, 255, 0.9);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
      }
    .login-form .password-label input{
      width: 60%;
    }.email-label input{
      width: 60%;
    }
      .form-container.blur {
        backdrop-filter: blur(20px);
      }
      .form-container h2{
        font-size: x-large;
        color: #00315b;
      }
  }
