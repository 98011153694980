@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Jacquard+24&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.primary-color{
    @apply text-transparent bg-clip-text bg-gradient-to-r from-red-950 to-purple-800 inline-block;
  }
  
  .bg-primary-color{
    @apply bg-gradient-to-r from-blue-800 to-blue-200 inline-block;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Regular.ttf");
  }@font-face{
    font-family: "Jacquard 24";
    src: url("./fonts/Montserrat-Regular.ttf");
  }